<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="arrow-btn">
          <img
            src="../assets/img/arrowBack.svg"
            class="img-pluss-btn"
            alt="arrow-back"
          />
        </div>
        <div class="vertical-line-1"></div>
        <div class="add-btn" @click="createItem">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>

    <div id="manufacturer">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="filteredStaffs"
              :search="search"
              :footer-props="footerProps"
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title"
                    >Персонал
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <div class="search-component">
                    <input
                      type="search"
                      class="search-component__input mr-10"
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="Поиск"
                      single-line
                      hide-details
                    />
                    <svg width="17" height="17" class="search-component__icon">
                      <use href="../assets/icons/icons.svg#search-icon"></use>
                    </svg>
                  </div>
                  <!-- <v-text-field
                    class="mr-10"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    hide-details
                  ></v-text-field> -->
                  <!-- <button id="staff-add-button" @click="createItem">
                    Создать новый ресторан +
                  </button> -->
                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        Персонал
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Полное имя</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea
                                class="col-description"
                                v-model="fullName"
                                ref="fullNameTextarea"
                                :placeholder="
                                  isfullNameFocused ? '' : textareaPlaceholder
                                "
                                @focus="isfullNameFocused = true"
                                @blur="isfullNameFocused = false"
                              ></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="role-container px-0">
                          <div class="d-flex align-items-center">
                            <p class="col-title" style="margin-right: 82px">
                              Роль
                            </p>
                            <span class="red-dot" style="margin-right: 15px"
                              >*</span
                            >
                          </div>

                          <!-- <v-select
                            v-model="newItem.applicationType"
                            :items="applicationTypes"
                            label="Выберите тип приложения"
                            item-text="label"
                            item-value="value"
                          /> -->

                          <div
                            @click="toggleInputMenu"
                            @mouseenter="addBorderClass"
                            @mouseleave="removeBorderClass"
                            ref="inputButton"
                            class="px-0 py-0 border-white"
                            :class="{ 'border-hover': isHovered }"
                          >
                            <div
                              class="select-btn"
                              :class="{
                                'border-input': showInputMenu,
                              }"
                            >
                              <p class="option-btn">
                                {{ selectedOption }}
                              </p>

                              <img
                                src="../assets/img/arrow-select.svg"
                                alt=""
                              />

                              <!-- Стрелочка -->
                            </div>
                          </div>
                          <div
                            class="input-menu"
                            v-if="showInputMenu"
                            ref="inputMenu"
                          >
                            <p
                              class="profile-menu-item"
                              @click="selectOption('Администратор')"
                            >
                              Администратор
                            </p>
                            <div class="divider-modal"></div>
                            <p
                              class="profile-menu-item"
                              @click="selectOption('Владелец')"
                            >
                              Владелец
                            </p>
                          </div>
                          <!-- <select
                              v-model="selectedRoles"
                              class="custom-select"
                            >
                              <option
                                v-for="item in roleTranslations"
                                :value="item"
                                :key="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                            <div class="select-arrow"></div> -->
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="col-content-2 px-0 py-4">
                          <v-col
                            cols="2"
                            class="col-paddings-r-l px-0 py-0 role-margin"
                          >
                            <p class="col-title">Ресторан</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-2">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="9" class="py-0 px-0">
                            <p class="text-limit">
                              {{ user.currentRest.restaurant.name }}
                            </p>
                          </v-col>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-4">
                          <div class="col-content px-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Телефон</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <input
                                class="col-description"
                                v-model="phone"
                                v-mask="'+7 (###) ###-##-##'"
                                ref="phoneTextarea"
                                :placeholder="
                                  isphoneFocused ? '' : textareaPlaceholder
                                "
                                @focus="phoneFocused = true"
                                @blur="phoneFocused = false"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <div class="btn-container">
                        <div class="delete-btn" text >
                          Деактивировать
                        </div>
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div class="save-btn" text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="570px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Вы точно хотите удалить данного работника?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div class="del-btn" text @click="deleteItemConfirm">
                          Удалить
                        </div>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                          Закрыть
                        </div>
                </template>
            </v-snackbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td @click="getEmployee(item)">
                    {{ item.contact.name || " " }}
                  </td>
                  <td @click="getEmployee(item)">
                    {{ getRoleTranslation(item.employeeRole) }}
                  </td>
                  <td @click="getEmployee(item)">{{ item.restaurant.name }}</td>
                  <td @click="getEmployee(item)">
                    {{ item.contact.mobilePhone }}
                  </td>
                  <td style="text-align: right">
                    <v-icon medium @click="deleteItem(item)"
                      >mdi-dots-vertical</v-icon
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
              <template v-slot:footer>
                <div class="v-data-footer">
                  <div class="v-data-footer__select">
                    Кол-во персонала на странице:
                    <v-select
                      class="custom-select"
                      v-model="itemsPerPage"
                      :items="footerProps.itemsPerPageOptions"
                      @change="changeItemsPerPage"
                      hide-details
                    ></v-select>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                    {{ totalItems }}
                  </div>
                  <div class="v-data-footer__icons-before">
                    <button
                      type="button"
                      :disabled="currentPage === 1"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === 1 }"
                      aria-label="Предыдущая страница"
                      @click="goToPreviousPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-left theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                  <div class="v-data-footer__icons-after">
                    <button
                      type="button"
                      :disabled="currentPage === totalPages"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === totalPages }"
                      aria-label="Следующая страница"
                      @click="goToNextPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-right theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { debounce } from 'lodash';
export default {
  directives: { mask },
  name: "Staff",

  data: () => ({
    isHovered: false,
    isHoveredTogo: false,
    showInputMenu: false,
    selectedOption: "",
    isPlaceholderHidden: false,
    isfullNameFocused: false,
    isphoneFocused: false,
    selectedRoles: null,
    fullName: "",
    phone: "",
    dialogDelete: false,
    dialogCreate: false,
    search: "",
    snackbar: false,
    message: "",
    roleTranslations: {
      Owner: "Владелец",
      Administrator: "Администратор",
    },
    newEmp: {
    },
    employee: {
    },
    selectedType: null,
    rolesTest: [
      { label: "CustomerApp", value: 1 },
      { label: "RestaurantApp", value: 2 },
      { label: "WebApp", value: 3 },
    ],
    headers: [
      {
        text: "Полное имя",
        align: "start",
        value: "contact.name",
      },
      { text: "Роль", value: "employeeRole" },
      { text: "Ресторан", value: "restaurant.name" },
      { text: "Телефон", value: "contact.mobilePhone" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    staffs: [],
    profiles: [],
    error: null,
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
    isEditing: false,
    currentEmp: "",
  }),

  created() {
    this.initialize();
    // this.profileInfo();
  },
  computed: {
    filteredStaffs() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.staffs.items
          .filter
          // (item) => item.rolesTest === this.selectedType
          ();
      } else {
        return this.staffs.items;
      }
    },
    footerProps() {
      return {
        itemsPerPageOptions: [3, 5, 7, 10], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideTogo);
  },

  methods: {
    getRoleTranslation(role) {
      return this.roleTranslations[role] || role;
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "fullName") {
        this.isfullNameFocused = true;
      }
      // } else if (inputName === "actualAdress") {
      //   this.isActualAdressFocused = true;
      // }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
      this.fullName = "";
      this.selectedOption = "";
      this.phone = "";
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        this.editEmployee();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewEmployee();
      }
    },
    initialize() {
      this.$http
        .put("/api/Employee/PagedList", {
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          filters: [
            {
              property: "RestaurantId",
              operator: "eq",
              value: this.user.currentRest.restaurant.id,
            },
          //   {
          //     property: "Contact.name",
          //     operator: "contains",
          //     value: this.search,
          //     nestedProperty: true,
          // },
          ],
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.staffs = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
        })
        .catch((error) => (this.error = error));
    },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
      this.initialize(); // Вызов метода для загрузки данных с новым количеством элементов на странице
    },
    deleteItem(item) {
      this.editedIndex = this.staffs.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(`/api/Employee/${this.editedItem.id}`)
        .then(() => {
          this.staffs.items.splice(this.editedIndex, 1);
          this.closeDelete();

          // Здесь мы устанавливаем флаг showSnackbar в true,
          // чтобы уведомление показалось после завершения удаления
          this.snackbar = true;
          this.message = "Сотрудник успешно удален"
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка удаления сотрудника";
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      // this.getEmployee();
    },

    closeCreate() {
      this.dialogCreate = false;
      this.fullName = "";
      this.selectedOption = "";
      this.phone = "";
    },

    createNewEmployee() {
      this.newEmp.employeeRole = this.getEnglishRoleTranslation(this.selectedOption);
      this.newEmp.name = this.fullName,
      this.newEmp.mobilePhone = this.phone,
      this.newEmp.restaurantId = this.user.currentRest.restaurant.id,
      this.$http
        .post("/api/Employee", this.newEmp)
        .then(() => {
          this.initialize();
          this.dialogCreate = false;
          this.newEmp = {
            restaurantId: "",
            name: "",
            mobilePhone: "",
            employeeRole: null,
          };
          this.snackbar = true;
          this.message = "Сотрудник успешно добавлен"
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка добавление сотрудника"
        });
    },
    getEmployee(item) {
        this.createItem();
        this.isEditing = true;
        this.editedIndex = this.staffs.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.currentEmp = item.id;
        this.$http
          .get(
            `/api/Employee/${item.id}`
          )
          .then((response) => {
            this.employee = response.data;
            this.fullName = this.employee.contact.name;
            this.selectedOption = this.getRoleTranslation(this.employee.employeeRole);
            this.phone = this.employee.contact.mobilePhone;
          })
          .catch((error) => {
            alert(error);
          });
      },
    editEmployee(){
      this.newEmp = {};
      this.newEmp.name = this.fullName;
      this.newEmp.employeeRole = this.getEnglishRoleTranslation(this.selectedOption);
      this.$http
            .put(`/api/Employee/${this.currentEmp}`, this.newEmp, {
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then((response) => {
              this.initialize();
              this.dialogCreate = false;
              this.employee = response.data;
              this.newEmp = {
              name: "",
              employeeRole: null,
              };
              this.snackbar = true;
              this.message = "Сотрудник успешно изменен";
            })
            .catch((error) => {
            alert(error);
            this.snackbar = true;
            this.message = "Ошибка редактирования сотрудника";
        });
    },
    // editItem(item) {
    //   this.$router.push({ path: `/admin/application/${item.id}` });
    // },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.showInputMenu = false;
      }
    },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
  },

  watch: {
    fullName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("fullNameTextarea");
      });
    },
    search: debounce(function() {
    // Выполняем запрос с задержкой после изменения переменной поиска
    this.initialize();
  }, 500),
    phone() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("phoneTextarea");
      });
    },
  },
};
</script>

<style scoped>


/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
  color: #fff; /* White text color */
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
  background-color: #357a38; /* Darker green background on hover */
}
.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}
.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}
.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0; /* Пример: изменение цвета фона при наведении */
  color: #333; /* Пример: изменение цвета текста при наведении */
  cursor: pointer; /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}
.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}
.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}
.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%; /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu {
  position: absolute;
  bottom: 32%;
  left: 30.7%;
  width: 347px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}
.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}
.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-left: 5px;
}
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 46px;
  align-items: center;
}
.red-dot-margin-2 {
  margin-left: -17px;
}
.red-dot-margin-1 {
  text-align: center;
  margin-left: -17px;
}
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}
.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.25rem;
    margin-right: auto;
    padding: 14px 16px;
    text-align: initial;
  cursor: pointer;

}
.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}
.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}
.text-limit {
  width: 30ch; /* Отображение примерно 10 символов */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-1, #333);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
}
.custom-select {
  position: relative;
  width: 350px;
  cursor: pointer;
  /* margin-left: 9px; */
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  /* transform: translateY(-50%); */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333; /* Цвет стрелочки */
}
.col-description,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}
.role-margin {
  margin-right: 37px;
}
.role-margin-1 {
  margin-right: 30px;
}

.col-content-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}
.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  height: 30px;
  /* min-height: 20px; */
  width: 100%;
  /* margin-left: 18px; */
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}
.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}
.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.search-component {
  max-width: 606px;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 10.5px;
  right: 55px;
}
#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}
::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid #848484;
}
</style>
